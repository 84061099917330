import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { withPreview } from 'gatsby-source-prismic';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';
import Container from '../components/global/container';
import HeroText from '../components/text/hero-text';
import BodyText from '../components/text/body-text';
import BodyImageCaption from '../components/text/body-image-caption';
import BodyImage from '../components/images/body-image';
import { HomepageSlideshow } from '../components/homepage/homepage-slideshow';
import HeroImage from '../components/images/hero-image';
import { VideoBanner } from '../components/video/video-banner';
import { VideoWithControls } from '../components/video/video-with-controls';

const TwoThreeImage = styled.div`
  position: relative;

  max-width: 640px;
  margin: 0 auto;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 150%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ThreeTwoImage = styled.div`
  position: relative;

  /* max-width: 640px; */
  margin: 0 auto;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const GalleryImageContainer = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Text = styled.div`
  max-width: 640px;
  margin: 0 auto;

  padding: ${props => props.padding};

  @media (max-width: 767px) {
    padding: ${props => props.mobilePadding || '0'};
  }

  & h1,
  & h2,
  & h3 {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    margin: 0;
  }

  & h1,
  & h2 {
    font-size: 24px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  & h3 {
    font-size: 14px;
    line-height: 24px;

    margin-bottom: 0;
  }

  & a {
    font-size: 14px;
    line-height: 100%;

    letter-spacing: 0.04em;

    color: rgba(35, 35, 35, 0.5);

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    &:hover {
      color: #934e2a;
    }
  }
`;

const SectionContainer = styled.div`
  margin: ${props => (props.margin ? props.margin : '30px auto')};

  width: 100%;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

const TitleWithTextContainer = styled.div`
  text-align: center;
  max-width: 840px;
  margin: 60px auto 100px auto;
  padding: 0 20px;

  & h1 {
    font-size: 28px;
    text-align: center;
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
    margin: 0 0 20px 0;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  & p {
    font-size: 18px;
    line-height: 160%;

    margin: 0 auto;
    color: #323232;
    text-align: center;
    max-width: 840px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.04em;
    }
  }

  @media (max-width: 767px) {
    margin: 30px auto;
  }
`;

const CollectionCard = styled.div`
  margin-bottom: 100px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 28px;
    text-align: center;
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    margin: 35px 0 20px 0;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    margin: 0 0 15px 0;
  }

  & p {
    font-size: 18px;
    line-height: 160%;

    margin: 0 auto;
    color: #323232;
    text-align: center;
    max-width: 660px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.04em;
    }
  }

  & .view-collection-link {
    display: block;
    width: 165px;
    height: 35px;
    text-align: center;
    background-color: #444e3e;
    color: #fff;
    line-height: 35px;

    margin: 25px auto;

    text-transform: uppercase;
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
    font-size: 12px;
    letter-spacing: 0.05em;
  }
`;

const ProductsContainer = styled.div`
  padding: 0;
`;

const Page = ({ data }) => {
  let allImages = data.prismicPage.data.body.filter(data => {
    return data.slice_type === 'full_width_image';
  });

  let seoImage;
  if (allImages.length >= 1) {
    seoImage = allImages[0].primary.image.url;
  } else {
    seoImage = null;
  }

  let allText = data.prismicPage.data.body.filter(data => {
    return (
      data.slice_type === 'centered_text' ||
      data.slice_type === 'image___text' ||
      data.slice_type === 'image_left_text_right'
    );
  });

  let seoText;
  if (allText.length >= 1) {
    seoText = allText[0].primary.text.text.substring(0, 240);
  } else {
    seoText = null;
  }

  const content = data.prismicPage.data.body.map((content, index) => {
    if (content.slice_type === 'full_width_image') {
      return (
        <SectionContainer
          margin={index === 0 ? `0 0 30px 0` : `30px 0`}
          key={content.id}
        >
          <HeroImage
            image={content.primary.image.url}
            alt={content.primary.image.alt}
            key={content.id}
          />
        </SectionContainer>
      );
    }
    if (content.slice_type === 'hero_slideshow') {
      return <HomepageSlideshow content={content} key={content.id} />;
    }

    if (content.slice_type === 'collections') {
      const collections = content.items.map((collection, index) => {
        return (
          <Col col={12} md={6} key={`single_collection_${index}`}>
            <CollectionCard>
              <a
                href={collection?.pageLink?.url}
                target={collection?.pageLink?.target}
              >
                <ThreeTwoImage>
                  <div>
                    {collection.image.fluid !== null && (
                      <img
                        srcSet={collection.image.fluid.srcSetWebp}
                        src={collection.image.fluid.srcWebp}
                        alt={collection.image.alt}
                        loading="lazy"
                      />
                    )}
                  </div>
                </ThreeTwoImage>
              </a>

              <h3>{collection.title1.text}</h3>

              <div
                dangerouslySetInnerHTML={{
                  __html: collection.text.html,
                }}
              />

              <a
                href={collection?.pageLink?.url}
                target={collection?.pageLink?.target}
                className="view-collection-link"
              >
                View Collection
              </a>
            </CollectionCard>
          </Col>
        );
      });

      return (
        <Container key={`single_collection_${index}`}>
          <Row justifyContent="center">
            <Col col={12}>
              <ProductsContainer>
                <Row justifyContent="center">{collections}</Row>
              </ProductsContainer>
            </Col>
          </Row>
        </Container>
      );
    }

    if (content.slice_type === 'slideshow') {
      const galleryImages = content.items.map((image, index) => (
        <GalleryImageContainer key={content.id + '__' + index}>
          <div>
            {image.image.fluid !== null && (
              <img
                srcSet={image.image.fluid.srcSetWebp}
                src={image.image.fluid.srcWebp}
                alt={image.image.alt}
                loading="lazy"
              />
            )}
          </div>
        </GalleryImageContainer>
      ));

      return (
        <Container key={content.id}>
          <SectionContainer margin={`30px 0`}>
            <Row justifyContent="center">
              <Col col={12} md={11} lg={10} xl={8}>
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop={true}
                  className="page-slideshow"
                >
                  {galleryImages}
                </Carousel>
              </Col>
            </Row>
          </SectionContainer>
        </Container>
      );
    }
    if (content.slice_type === 'image___text') {
      if (index === 1) {
        return (
          <Container key={content.id}>
            <SectionContainer padding={'30px 0'}>
              <Row justifyContent="center" alignItems="center">
                <Col col={12} md={6} order={1} smOrder={1}>
                  <Text
                    padding={'0 30px 0 0'}
                    mobilePadding={'0 0 30px 0'}
                    dangerouslySetInnerHTML={{
                      __html: content.primary.text.html,
                    }}
                  />
                  {content.primary.page_link && (
                    <Text padding={'0 30px 0 0'} mobilePadding={'15px 0 0 0'}>
                      <Link to={content.primary.page_link}>
                        Shop The Collection
                      </Link>
                    </Text>
                  )}
                </Col>

                <Col col={7} md={5} lg={5} xl={4} order={12} smOrder={12}>
                  <TwoThreeImage>
                    <div>
                      {content.primary.image.fluid !== null && (
                        <img
                          srcSet={content.primary.image.fluid.srcSetWebp}
                          src={content.primary.image.fluid.srcWebp}
                          alt={content.primary.image.alt}
                          loading="lazy"
                        />
                      )}
                    </div>
                  </TwoThreeImage>
                </Col>
              </Row>
            </SectionContainer>
          </Container>
        );
      } else {
        return (
          <Container key={content.id}>
            <SectionContainer padding={'30px 0'}>
              <Row justifyContent="center" alignItems="center">
                <Col col={12} md={6} order={12} smOrder={1}>
                  <Text
                    padding={'0 30px 0 0'}
                    mobilePadding={'30px 0 0 0'}
                    dangerouslySetInnerHTML={{
                      __html: content.primary.text.html,
                    }}
                  />
                  {content.primary.page_link && (
                    <Text padding={'0 30px 0 0'} mobilePadding={'15px 0 0 0'}>
                      <Link to={content.primary.page_link}>
                        Shop The Collection
                      </Link>
                    </Text>
                  )}
                </Col>

                <Col col={7} md={5} lg={5} xl={4} order={1} smOrder={12}>
                  <TwoThreeImage>
                    <div>
                      {content.primary.image.fluid !== null && (
                        <img
                          srcSet={content.primary.image.fluid.srcSetWebp}
                          src={content.primary.image.fluid.srcWebp}
                          alt={content.primary.image.alt}
                          loading="lazy"
                        />
                      )}
                    </div>
                  </TwoThreeImage>
                </Col>
              </Row>
            </SectionContainer>
          </Container>
        );
      }
    }
    if (content.slice_type === 'centred_title_with_text') {
      return (
        <TitleWithTextContainer key={content.id}>
          <div
            dangerouslySetInnerHTML={{ __html: content.primary.title1.html }}
          />
          <div
            dangerouslySetInnerHTML={{ __html: content.primary.text.html }}
          />
        </TitleWithTextContainer>
      );
    }
    if (content.slice_type === 'image_left_text_right') {
      return (
        <Container key={content.id}>
          <SectionContainer padding={'30px 0'}>
            <Row justifyContent="center" alignItems="center">
              <Col col={7} md={5} lg={5} xl={4}>
                <TwoThreeImage>
                  <div>
                    {content.primary.image.fluid !== null && (
                      <img
                        srcSet={content.primary.image.fluid.srcSetWebp}
                        src={content.primary.image.fluid.srcWebp}
                        alt={content.primary.image.alt}
                        loading="lazy"
                      />
                    )}
                  </div>
                </TwoThreeImage>
              </Col>
              <Col col={12} md={6}>
                <Text
                  padding={'0 0 0 30px'}
                  mobilePadding={'30px 0 0 0'}
                  dangerouslySetInnerHTML={{
                    __html: content.primary.text.html,
                  }}
                />
                {content.primary.page_link && (
                  <Text padding={'0 0 0 30px'} mobilePadding={'15px 0 0 0'}>
                    <Link to={content.primary.page_link}>
                      Shop The Collection
                    </Link>
                  </Text>
                )}
              </Col>
            </Row>
          </SectionContainer>
        </Container>
      );
    }
    if (content.slice_type === 'centered_text') {
      return (
        <SectionContainer
          padding={`30px 0`}
          key={content.id}
          margin={`30px auto`}
        >
          <BodyText
            text={content.primary.text}
            padding={'0'}
            margin={'0 auto'}
            link={content.primary.page_link}
          />
        </SectionContainer>
      );
    }
    if (content.slice_type === 'pull_quote') {
      return (
        <SectionContainer key={content.id}>
          <HeroText
            text={content.primary.text}
            padding={'0'}
            mobilePadding={'0'}
            key={content.id}
          />
        </SectionContainer>
      );
    }
    if (content.slice_type === 'centered_image') {
      return (
        <SectionContainer key={content.id}>
          {content.primary.image.fluid !== null && (
            <BodyImage
              // image={content.primary.image.url}
              srcWebp={content.primary.image.fluid.srcWebp}
              srcSetWebp={content.primary.image.fluid.srcSetWebp}
            />
          )}
          {content.primary.image.alt && (
            <BodyImageCaption text={content.primary.image.alt} />
          )}
        </SectionContainer>
      );
    }

    if (content.slice_type === 'full_width_video') {
      return <VideoBanner content={content} key={content.id} />;
    }

    if (content.slice_type === 'video_with_controls') {
      return (
        <SectionContainer key={content.id}>
          <VideoWithControls content={content} />
        </SectionContainer>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicPage.data.title.text} – Mourne Textiles`}
        meta={[
          {
            name: 'description',
            content: `${seoText}...`,
          },
          {
            name: 'og:image',
            content: `${seoImage}`,
          },
          {
            name: 'og:image:secure_url',
            content: `${seoImage}`,
          },
          {
            name: 'og:description',
            content: `${seoText}...`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `Mourne Textiles`,
          },
          {
            name: 'twitter:description',
            content: `${seoText}...`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${seoImage}`,
          },
        ]}
      />

      {content}
    </>
  );
};

export default withPreview(Page);

export const query = graphql`
  query PageQuery($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      data {
        title {
          text
        }
        body {
          ... on PrismicPageBodyCentredTitleWithText {
            id
            slice_type
            primary {
              title1 {
                html
                text
              }
              text {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyCollections {
            slice_type
            id
            items {
              image {
                alt
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 400) {
                  srcWebp
                  srcSetWebp
                }
              }
              title1 {
                text
              }
              text {
                html
              }
              pageLink: link {
                url
                target
              }
            }
          }
          ... on PrismicPageBodyHeroSlideshow {
            id
            slice_type
            items {
              image {
                fluid {
                  srcWebp
                  srcSetWebp
                }
                alt
              }
              button_text
              call_to_action_text {
                text
              }
              text {
                html
              }
              pageLink: link {
                url
                target
              }
              image_slide
              video {
                html
                width
                height
                embed_url
              }
            }
          }
          ... on PrismicPageBodyCenteredImage {
            id
            primary {
              image {
                alt
                url
                fluid(maxWidth: 800) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
            slice_type
          }
          ... on PrismicPageBodyCenteredText {
            id
            slice_type
            primary {
              page_link
              text {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyFullWidthImage {
            id
            slice_type
            primary {
              image {
                alt
                url
                fluid(maxWidth: 1200) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          ... on PrismicPageBodyImageLeftTextRight {
            id
            slice_type
            primary {
              page_link
              image {
                alt
                url
                fluid(maxWidth: 600) {
                  srcWebp
                  srcSetWebp
                }
              }
              text {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyImageText {
            id
            slice_type
            primary {
              page_link
              image {
                alt
                url
                fluid(maxWidth: 800) {
                  srcWebp
                  srcSetWebp
                }
              }
              text {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyPullQuote {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPageBodySlideshow {
            id

            slice_type
            items {
              image {
                alt
                url
                fluid(maxWidth: 800) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          ... on PrismicPageBodyFullWidthVideo {
            id
            slice_type
            primary {
              video {
                html
                width
                height
                embed_url
              }
              call_to_action_text {
                html
              }
              link
              button_text
            }
          }
          ... on PrismicPageBodyVideoWithControls {
            id
            slice_type
            primary {
              video {
                html
                height
                width
                embed_url
              }
            }
          }
        }
      }
      uid
    }
  }
`;
